/**************************************************
 * Nombre:       Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Portada_Catalogo from "./Componentes/Portada_Catalogo";

import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import Coleccion_Portada from "./Componentes/Coleccion_Portada";
import Colecciones_Anteriores_Portada from "./Componentes/Colecciones_Anteriores_Portada";
import FraseHumanizadora from "./Componentes/FraseHumanizadora";
import TrajesFormales from "./Componentes/TrajesFormales";
import {irArriba} from "../../Utilidades/irArriba";

const Seccion_Catalogo = () => {
    const {sCell} = useResponsive()
    const parms = useParams()
    const [coleccion, setColeccion] = useState({})

    useEffect(() => {
        irArriba()



        if (parms && parms.id) {
            let nomColeccion = decodeURI(parms.id)
            console.log(parms.id)

            let q = query(collection(fire, 'colecciones'), where('nombre', '==', nomColeccion))
            obtenerColeccionQuery(q).then((des) => {
                console.log(des)
                if (des.res && des.data && des.data[0]) {
                    setColeccion(des.data[0])
                }
            })

        } else {
            obtenerDoc('configuraciones', 'home').then((dox) => {
                if (dox.res) {
                    let colec = dox.data
                    let q = query(collection(fire, 'colecciones'), where('nombre', '==', colec.coleccion))
                    obtenerColeccionQuery(q).then((des) => {
                        if (des.res && des.data && des.data[0]) {
                            setColeccion(des.data[0])
                        }
                    })
                }
            })
        }
    }, [parms]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Portada_Catalogo coleccion={coleccion}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 8}}>
                <Coleccion_Portada coleccion={coleccion}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Colecciones_Anteriores_Portada coleccion={coleccion}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? -6 : 4}}>
                <FraseHumanizadora/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? -2 : 6}}>
                <TrajesFormales/>
            </Grid>

        </Grid>
    )

}
export default Seccion_Catalogo